import { selectActiveCheckoutValueId } from '../../slices/checkout-slice';
import { OTPVerificationOptionType, selectActiveOtpRequestId } from '../../slices/otp-slice';
import {
    selectPrizeoutUserSession,
    selectConsumerOnboardingFlowId,
    MiniWidgetDisplayTypes,
    NavBarButtonIconNames,
} from '../../slices/partner-config-slice';
import { selectPartnerId } from '../../slices/sdk-slice';
import { selectActiveGiftCardRequestId } from '../../slices/wallet-slice';
import store from '../../store';
import { getDeviceType } from '../helpers';
import { analyticsApi } from './api';

type trackDataPayload = {
    event_type: string;
    partner_id: string;
    created_at: number;
    payload: Record<string, unknown>;
    session_id?: string;
};

//TODO: Add button event for all usage of the status notification

export enum ButtonTrackingTypes {
    OFFER_CATEGORY_BACK_BUTTON_CLICK = 'offer_category_back_button_click',
    BRANDS_BACK_BUTTON_CLICK = 'brands_back_button_click',
    WALLET_ARCHIVE_BACK_BUTTON_CLICK = 'wallet_archive_back_button_click',
    GIFTING_HEADER_BACK_BUTTON_CLICK = 'gifting_header_back_button_click',

    CHECKOUT_PANEL_CLOSE_BUTTON_CLICK = 'checkout_panel_close_button_click',
    CHECKOUT_PANEL_BRAND_INFO_CLOSE_BUTTON_CLICK = 'checkout_panel_brand_info_close_button_click',

    ACCOUNT_LINK_REQUEST_BUTTON_CLICK = 'account_link_request_button_click',
    ACCOUNT_LINK_CANCEL_BUTTON_CLICK = 'account_link_cancel_button_click',

    ACCOUNT_VERIFICATION_RENEW_BUTTON_CLICK = 'account_verification_renew_button_click',
    ACCOUNT_CANCEL_VERIFICATION_RENEW_BUTTON_CLICK = 'account_cancel_verification_renew_button_click',

    ACCOUNT_VERIFICATION_SENT_BUTTON_CLICK = 'account_verification_sent_button_click',

    BUTTON_CLICK_ACCEPT_REWARDS_TERMS = 'button_click_accept_rewards_terms',
    BUTTON_CLICK_EXIT = 'button_click_exit',
    BUTTON_CLICK_EXPLORE = 'button_click_explore',
    BUTTON_CLICK_LOGIN = 'button_click_login',
    BUTTON_CLICK_FORGOT_PASSWORD = 'button_click_forgot_password',
    BUTTON_CLICK_CLOSE_PILLS_LIST = 'button_click_close_pills_list',
    BUTTON_CLICK_CLEAR_PILLS_LIST = 'button_click_clear_pills_list',
    BUTTON_CLICK_SIDEBAR_BACK_ARROW = 'button_click_sidebar_back_arrow',
    BUTTON_CLICK_SIDEBAR_FORWARD_ARROW = 'button_click_sidebar_forward_arrow',
    BUTTON_CLICK_SIGN_UP = 'button_click_sign_up',
    BUTTON_CLICK_BE_LOG_IN = 'button_click_browser_extension_log_in',
    BUTTON_CLICK_BE_HELP = 'button_click_browser_extension_help',
    PILL_CLICK = 'pill_click',
}

export enum CashbackTransferTrackingTypes {
    TRANSFER_CASHBACK = 'transfer_cashback',
    TRANSFER_CASHBACK_CONFIRM = 'transfer_cashback_confirm',
    SELECT_ACCOUNT_CASHBACK = 'select_an_account_cashback',
    SELECT_ACCOUNT_CONFIRM_CASHBACK = 'select_account_confirm_cashback',
}

export enum CheckboxTrackingTypes {
    OTP_REMEMBER_DEVICE = 'otp_remember_device',
}

export enum FavoritesTrackingTypes {
    BRAND_FAVORITE_CLICK = 'brand_favorite_click',
    BRAND_FAVORITE_SEND = 'brand_favorite_send',
    CATEGORY_FAVORITE_CLICK = 'category_favorite_click',
    CATEGORY_FAVORITE_SEND = 'category_favorite_send',
}

export enum SortListTrackingTypes {
    SORT_LIST_CLICK = 'sort_list_click',
}

export enum MiniWidgetTrackingEventTypes {
    MINI_WIDGET_BILLBOARD_LOAD = 'mini_widget_billboard_load',
    MINI_WIDGET_CLICK_EXIT_PRIZEOUT = 'mini_widget_click_exit_prizeout',
    MINI_WIDGET_SEE_MORE_CLICK = 'mini_widget_see_more_click',
    MINI_WIDGET_TILE_LOADED = 'mini_widget_tile_loaded',
    MINI_WIDGET_ERROR_SCREEN_DISPLAYED = 'mini_widget_error_screen_displayed',
    MINI_WIDGET_OFFER_CLICKED = 'mini_widget_offer_clicked',
}

export enum MiniWidgetButtonTypes {
    OFFER_CLICK = 'offer_click',
    MY_WALLET_CLICK = 'my_wallet_click',
    MY_CASHBACK_CLICK = 'my_cashback_click',
    EXPLORE_OFFERS_CLICK = 'explore_offers_click',
}

export enum NavBarLocation {
    HEADER = 'header',
    BOTTOM = 'bottom',
}

export enum OnboardingStepTypes {
    ONBOARDING_SPLASH = 'onboarding_splash',
    ONBOARDING_BRAND_FAVORITES = 'onboarding_brand_favorites',
    ONBOARDING_CATEGORY_FAVORITES = 'onboarding_category_favorites',
}

export enum OnboardingTrackingTypes {
    ONBOARDING_CONTINUE = 'onboarding_continue',
    ONBOARDING_EXIT = 'onboarding_exit',
    ONBOARDING_OPEN = 'onboarding_open',
}

export enum OtpTrackingTypes {
    OTP_SEND_NEW_CODE = 'otp_send_new_code',
    OTP_SEND_REQUEST = 'otp_send_request',
    OTP_SUBMIT = 'otp_submit',
}

export enum PostCheckoutButtonTypes {
    BROWSE_MORE_OFFERS = 'browse_more_offers',
    CLOSE = 'close',
    VIEW_WALLET = 'view_wallet',
    VIEW_POINTS = 'view_points',
    VIEW_CASHBACK = 'view_cashback',
}

export enum SearchTrackingTypes {
    SEARCH_FILTER_CLICK = 'search_filter_click',
    SEARCH_CLEAR_QUERY_CLICK = 'search_clear_query_click',
    SEARCH_FILTER_CATEGORY_CLICK = 'search_filter_category_click',
    SEARCH_FILTER_CATEGORY_CONFIRM = 'search_filter_category_confirm',
    SEARCH_ITEM_SELECTED = 'search_item_selected',
}

export enum SearchTrackingVariationType {
    WALLET = 'wallet',
    BRAND = 'brand',
}

export enum SidebarMenuTrackingTypes {
    SIDEBAR_ABOUT_PRIZEOUT_CLICK = 'sidebar_about_prizeout_click',
    SIDEBAR_FAQ_CLICK = 'sidebar_faq_click',
    SIDEBAR_MY_POINTS_CLICK = 'sidebar_my_points_click',
    SIDEBAR_MY_PROFILE_CLICK = 'sidebar_my_profile_click',
    SIDEBAR_MY_WALLET_CLICK = 'sidebar_my_wallet_click',
    SIDEBAR_MY_CASHBACK_CLICK = 'sidebar_my_cashback_click',
    SIDEBAR_HOME_CLICK = 'sidebar_home_click',
    SIDEBAR_BROWSER_EXTENSION = 'sidebar_browser_extension',
}

export enum WalletTrackingTypes {
    WALLET_ARCHIVE_CLICK = 'wallet_archive_click',
    WALLET_ARCHIVE_CONFIRM = 'wallet_archive_confirm',
    WALLET_UNARCHIVE_CLICK = 'wallet_unarchive_click',
    WALLET_UNARCHIVE_CONFIRM = 'wallet_unarchive_confirm',
    WALLET_VIEW_DETAILS_CLICK = 'wallet_view_details_click',
}

export enum PointsTrackingTypes {
    REDEEM_WITH_POINTS = 'redeem_with_points',
    CONFIRM_CHECKOUT_POINTS = 'confirm_checkout_points',
}

export enum GiftingTrackingTypes {
    SEND_GIFT_NOW = 'send_gift_now',
    CONFIRM_CONTACTS = 'confirm_contacts',
    ADD_CONTACT = 'add_contact',
    CREATE_CONTACT = 'create_contact',
    UPDATE_CONTACT = 'update_contact',
    SAVE_CONTACT = 'save_contact',
    CONFIRM_ACCOUNT_SELECTION = 'confirm_account_selection',
    SEND_TYPE = 'send_type',
    SEND_GIFT = 'send_gift',
    SEND_ANOTHER_GIFT = 'send_another_gift',
}

export enum AutoApplyRuleTrackingTypes {
    RULESET_START = 'ruleset_start',
    RULESET_SUCCESS = 'ruleset_success',
    RULESET_FAIL = 'ruleset_fail',
}

enum GeneralTrackingServiceEventTypes {
    UNDEFINED_USER_EVENT = 'undefined_user_event', // no reason to ever track this; all events sent should be defined
    ACCOUNT_CLICK_EXIT_PRIZEOUT = 'account_click_exit_prizeout',
    ADSET_CONFIRM_CLICK_CASH_OUT = 'adset_confirm_click_cash_out',
    ADSET_CONFIRM_CLICK_EDIT = 'adset_confirm_click_edit',
    ADSET_CONFIRM_LOAD_COMPLETE = 'adset_confirm_load_complete',
    ADSET_EDIT_CLICK_CANCEL = 'adset_edit_click_cancel',
    ADSET_EDIT_CLICK_CARD_VALUES = 'adset_edit_click_card_values',
    ADSET_EDIT_LOAD_COMPLETE = 'adset_edit_load_complete',
    AUTH_DISPLAY = 'auth_display',
    AUTH_SIGNUP = 'auth_signup',
    BROWSER_BACK_CLICK = 'browser_back_click',
    CARD_CLICK = 'card_click',
    CATEGORY_CLICK = 'category_click',
    CHECKOUT_COMPLETE = 'checkout_complete',
    CHECKOUT_ERROR = 'checkout_error',
    COMPLETE_PURCHASE = 'complete_purchase',
    CONFIRM_EMAIL_CAPTURE = 'confirm_email_capture',
    CONFIRMATION_LOADCOMPLETE = 'confirmation_load_complete',
    CONFIRMATION_LOADREQUEST = 'confirmation_load_request',
    CONFIRM_REG_E = 'confirm_reg_e',
    DAY_OF_BIRTHDAY_CLOSE = 'day_of_birthday_close',
    DAY_OF_BIRTHDAY_OPEN = 'day_of_birthday_open',
    GIFTCARD_CASHOUT_FAIL = 'giftcard_cashout_fail',
    GIFTCARD_CASHOUT_SUCCESS = 'giftcard_cashout_success',
    GIFT_CARD_DETAILS_PAGE_LOAD = 'gift_card_details_page_load',
    HEADER_BAR_CLICK = 'header_bar_click',
    HOME_CLICK_EXIT_PRIZEOUT = 'home_click_exit_prizeout',
    HOMEPAGE_ADSET_CLICK = 'homepage_adset_click',
    HOMEPAGE_CHANGE_CARDVALUE = 'homepage_change_cardvalue',
    HOMEPAGE_CREATE_SESSION_COMPLETE = 'homepage_create_session_complete',
    HOMEPAGE_FOOTER_CLICK = 'homepage_footer_click',
    HOMEPAGE_LOAD_COMPLETE = 'homepage_load_complete',
    HOMEPAGE_LOAD_REQUEST = 'homepage_load_request',
    HOMEPAGE_OFFERS_COMPLETE = 'homepage_offers_complete',
    HOMEPAGE_SCROLL = 'homepage_scroll',
    INITIAL_LOAD_TIME = 'initial_load_time',
    LOCATION_EMPTY_OFFERS_POP = 'location_empty_offers_pop',
    MORE_DETAILS_REDIRECT_URL_CLICK = 'more_details_redirect_url_click',
    NAV_BAR_CLICK = 'nav_bar_click',
    NAVIGATION = 'navigation',
    NO_OFFERS_POP = 'no_offers_pop',
    OFFER_PAGE_CLICK_EXIT_PRIZEOUT = 'offer_page_click_exit_prizeout',
    OFFERS_CLICK_EXIT_PRIZEOUT = 'offers_click_exit_prizeout',
    OPEN_CHECKOUT_PAGE_EMAIL_MODAL = 'open_checkout_page_email_modal',
    OPEN_CHECKOUT_PAGE_IN_STORE_MODAL = 'open_checkout_page_in_store_modal',
    OPT_INS_COMPLETE = 'opt_ins_complete',
    OPT_INS_OPEN = 'opt_ins_open',
    OPT_INS_REQUEST = 'opt_ins_request',
    OPT_PRODUCT_TOUR_CLICKED_OVERLAYINS_OPEN = 'opt_product_tour_clicked_overlayins_open',
    POST_CHECKOUT_CLICK = 'post_checkout_click',
    PRODUCT_TOUR_BUBBLE_OPEN = 'product_tour_bubble_open',
    PRODUCT_TOUR_CLICK = 'product_tour_click',
    PRODUCT_TOUR_CLOSE = 'product_tour_close',
    PRODUCT_TOUR_ERROR = 'product_tour_error',
    PRODUCT_TOUR_OPEN = 'product_tour_open',
    QUESTION_MARK_CLICKED = 'question_mark_clicked',
    SDK_INIT_NO_EMAIL_FAILURE = 'sdk_init_no_email_failure',
    SHOP_NOW_EXTERNAL_LINK_CLICK = 'shop_now_external_link_click',
    SLIDER_EVENT = 'slider_event',
    START_PRIZEOUT_CLICK = 'start_prizeout_click',
    STRIPE_CARD_SETUP_FAILED = 'stripe_card_setup_failed',
    STRIPE_CARD_SETUP_SUCCESS = 'stripe_card_setup_success',
    USE_GIFT_CARD_EXTERNAL_LINK_CLICK = 'use_gift_card_external_link_click',
    WEBSITE_CLICK_EXIT_PRIZEOUT = 'website_click_exit_prizeout',
    WEBSITE_HOMEPAGE_LOAD_COMPLETE = 'website_homepage_load_complete',
    WIDGET_ENTRY_EMAIL_POP = 'widget_entry_email_pop',
    WIDGET_ENTRY_EMAIL_SUBMIT = 'widget_entry_email_submit',
    YOUR_GIFTCARDS_CLICK_EXIT_PRIZEOUT = 'your_giftcards_click_exit_prizeout',
}

export const TrackingServiceEventTypes = {
    ...ButtonTrackingTypes,
    ...FavoritesTrackingTypes,
    ...CashbackTransferTrackingTypes,
    ...CheckboxTrackingTypes,
    ...GeneralTrackingServiceEventTypes,
    ...MiniWidgetTrackingEventTypes,
    ...OnboardingTrackingTypes,
    ...OtpTrackingTypes,
    ...SidebarMenuTrackingTypes,
    ...WalletTrackingTypes,
    ...AutoApplyRuleTrackingTypes,
};

export type TrackingServiceEventTypes = typeof TrackingServiceEventTypes;

export class TrackingService {
    private static trackingInstance: TrackingService = null;

    public static getTrackingService() {
        if (!TrackingService.trackingInstance) {
            TrackingService.trackingInstance = new TrackingService();
        }

        return TrackingService.trackingInstance;
    }

    public async trackData(eventType: string, eventData: Record<string, unknown> = {}): Promise<void> {
        try {
            await this.sendTrackDataPayload(eventType, eventData);
        } catch (e) {
            this.failureToTrackData(e);
        }
    }

    private failureToTrackData(e: Error): void {
        console.warn('Failure to track event data', e); // eslint-disable-line
    }

    private async sendTrackDataPayload(eventType: string, eventData: Record<string, unknown> = {}): Promise<void> {
        const defaultEventType = TrackingServiceEventTypes.UNDEFINED_USER_EVENT;
        const millisecondsFromEpoc = new Date().getTime();
        const state = store.getState();

        const data: trackDataPayload = {
            event_type: eventType || defaultEventType,
            partner_id: selectPartnerId(state),
            created_at: millisecondsFromEpoc,
            payload: {
                ...eventData,
                clientDeviceCategory: getDeviceType(),
                userAgent: navigator.userAgent,
            },
        };

        const session_id = selectPrizeoutUserSession(state);
        if (session_id) {
            data.session_id = session_id;
        }

        // Don't error out altogether if the network call fails.
        try {
            await analyticsApi({ data });
        } catch (err) {
            console.error('Analytics API send failed with error:', err, data);
        }
    }

    public async trackCashbackTransferAction(
        cashbackTrackingType: CashbackTransferTrackingTypes,
        eventPayload?: any,
    ): Promise<void> {
        await this.trackData(cashbackTrackingType, eventPayload);
    }

    public async trackExitPrizeout(): Promise<void> {
        const eventType = TrackingServiceEventTypes.ACCOUNT_CLICK_EXIT_PRIZEOUT;
        await this.trackData(eventType);
    }

    public async trackPointsEvent(eventType: PointsTrackingTypes, eventPayload?: any): Promise<void> {
        await this.trackData(eventType, eventPayload);
    }

    public async trackGiftingEvent(eventType: GiftingTrackingTypes, eventPayload?: any): Promise<void> {
        await this.trackData(eventType, eventPayload);
    }

    public async trackAdsetConfirmClickEdit(): Promise<void> {
        const eventType = TrackingServiceEventTypes.ADSET_CONFIRM_CLICK_EDIT;
        await this.trackData(eventType);
    }

    public async trackAdsetConfirmLoadComplete(): Promise<void> {
        const eventType = TrackingServiceEventTypes.ADSET_CONFIRM_LOAD_COMPLETE;
        await this.trackData(eventType);
    }

    public async trackAdsetEditClickCancel(): Promise<void> {
        const eventType = TrackingServiceEventTypes.ADSET_EDIT_CLICK_CANCEL;
        await this.trackData(eventType);
    }

    public async trackSearchItemSelected(
        id: string,
        name: string,
        location: string,
        type: SearchTrackingVariationType,
    ): Promise<void> {
        const eventType = SearchTrackingTypes.SEARCH_ITEM_SELECTED;
        const eventPayload = {
            item_id: id,
            item_name: name,
            item_type: type,
            item_location: location,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackAdsetConfirmClickCashOut(checkoutValueId: string, categoryId?: number): Promise<void> {
        const eventType = TrackingServiceEventTypes.ADSET_CONFIRM_CLICK_CASH_OUT;
        const eventPayload = {
            checkout_value_id: checkoutValueId,
            category_id: categoryId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackAdsetEditClickCardValues(checkoutValueId: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.ADSET_EDIT_CLICK_CARD_VALUES;
        const eventPayload = {
            checkout_value_id: checkoutValueId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackAdsetEditLoadComplete(
        location: string,
        checkoutValueId: string,
        categoryId?: number,
    ): Promise<void> {
        const eventType = TrackingServiceEventTypes.ADSET_EDIT_LOAD_COMPLETE;
        const eventPayload = {
            location,
            checkout_value_id: checkoutValueId,
            category_id: categoryId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackAuthDisplay(origin: string, type: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.AUTH_DISPLAY;
        const eventPayload = {
            origin,
            type,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackAuthSignup(origin: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.AUTH_SIGNUP;
        const eventPayload = {
            origin,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackBrowserBackClick(pageUrl: string, didExitPrizeout: boolean): Promise<void> {
        const eventType = TrackingServiceEventTypes.BROWSER_BACK_CLICK;
        const eventPayload = {
            page_url: pageUrl,
            did_exit_prizeout: didExitPrizeout,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackButtonClick(
        buttonTrackingType: ButtonTrackingTypes,
        buttonType: string,
        ariaLabel: string,
    ): Promise<void> {
        const eventType = buttonTrackingType;
        const eventPayload = {
            type: buttonType,
            ariaLabel: ariaLabel,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackCardClick(cardName: string): Promise<void> {
        const eventType = `${cardName}_${TrackingServiceEventTypes.CARD_CLICK}`;
        const eventPayload = {
            card_name: cardName,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackCategoryClick(categoryId: number): Promise<void> {
        const eventType = TrackingServiceEventTypes.CATEGORY_CLICK;
        const eventPayload = {
            category_id: categoryId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackCheckboxOptIn(checkboxTrackingType: CheckboxTrackingTypes, checkboxId: string): Promise<void> {
        const eventType = checkboxTrackingType;
        const eventPayload = {
            checkbox_id: checkboxId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async sortListClick(
        activeCategoryId: number,
        activeCategoryName: string,
        activeSortType: string,
        activeFilterName: string,
    ): Promise<void> {
        const eventType = SortListTrackingTypes.SORT_LIST_CLICK;
        const eventPayload = {
            active_category_id: activeCategoryId,
            active_category_name: activeCategoryName,
            active_sort_type: activeSortType,
            active_filter_name: activeFilterName,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackCheckoutComplete(checkoutValueId: string, categoryId?: number): Promise<void> {
        const eventType = TrackingServiceEventTypes.CHECKOUT_COMPLETE;
        const eventPayload = {
            checkout_value_id: checkoutValueId,
            category_id: categoryId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackCheckoutError(
        giftCardCost: number,
        giftCardId: string,
        userBalance: number,
        categoryId?: number,
    ): Promise<void> {
        const eventType = TrackingServiceEventTypes.CHECKOUT_ERROR;
        const eventPayload = {
            gift_card_cost: giftCardCost,
            gift_card_id: giftCardId,
            user_balance: userBalance,
            category_id: categoryId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackEmailCapture(checkout_flow_id: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.CONFIRM_EMAIL_CAPTURE;
        await this.trackData(eventType, {
            checkout_flow_id,
        });
    }

    public async trackCompletePurchase(checkout_flow_id: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.COMPLETE_PURCHASE;
        await this.trackData(eventType, {
            checkout_flow_id,
        });
    }

    public async trackConfirmationLoadComplete(): Promise<void> {
        const eventType = TrackingServiceEventTypes.CONFIRMATION_LOADCOMPLETE;
        await this.trackData(eventType);
    }

    public async trackConfirmationLoadRequest(): Promise<void> {
        const eventType = TrackingServiceEventTypes.CONFIRMATION_LOADREQUEST;
        await this.trackData(eventType);
    }

    public async trackConfirmRegE(checkoutValueId: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.CONFIRM_REG_E;
        const eventPayload = {
            checkout_value_id: checkoutValueId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackDayOfBirthdayClose(): Promise<void> {
        const eventType = TrackingServiceEventTypes.DAY_OF_BIRTHDAY_CLOSE;
        await this.trackData(eventType);
    }

    public async trackDayOfBirthdayOpen(): Promise<void> {
        const eventType = TrackingServiceEventTypes.DAY_OF_BIRTHDAY_OPEN;
        await this.trackData(eventType);
    }

    public async trackFavoritesAction(favoritesTrackingType: FavoritesTrackingTypes, selected: boolean): Promise<void> {
        const eventType = favoritesTrackingType;
        const eventPayload = { selected };
        await this.trackData(eventType, eventPayload);
    }

    public async trackGiftCardCashoutFail(): Promise<void> {
        const eventType = TrackingServiceEventTypes.GIFTCARD_CASHOUT_FAIL;
        await this.trackData(eventType);
    }

    public async trackGiftCardCashoutSuccess(): Promise<void> {
        const eventType = TrackingServiceEventTypes.GIFTCARD_CASHOUT_SUCCESS;
        await this.trackData(eventType);
    }

    public async trackGiftCardDetailsPageLoad(gift_card_request_id: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.GIFT_CARD_DETAILS_PAGE_LOAD;

        const eventPayload = {
            gift_card_request_id,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackClaimGiftCardDetailsPageLoad(requestId: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.GIFT_CARD_DETAILS_PAGE_LOAD;
        const eventPayload = {
            request_id: requestId,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackHomeClickExitPrizeout(pageUrl: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.HOME_CLICK_EXIT_PRIZEOUT;
        const eventPayload = {
            page_url: pageUrl,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackHomepageAdsetClick(): Promise<void> {
        const eventType = TrackingServiceEventTypes.HOMEPAGE_ADSET_CLICK;
        await this.trackData(eventType);
    }

    public async trackHomepageChangeCardValue(): Promise<void> {
        const eventType = TrackingServiceEventTypes.HOMEPAGE_CHANGE_CARDVALUE;
        await this.trackData(eventType);
    }

    public async trackHomepageFooterClick(link: string, pageUrl: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.HOMEPAGE_FOOTER_CLICK;
        const eventPayload = {
            link,
            page_url: pageUrl,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackHomepageLoadComplete(trace_id: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.HOMEPAGE_LOAD_COMPLETE;
        const eventPayload = { trace_id };
        await this.trackData(eventType, eventPayload);
    }

    public async trackHomepageLoadRequest(trace_id: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.HOMEPAGE_LOAD_REQUEST;
        const height = window.innerHeight;
        const width = window.innerWidth;
        const eventPayload = {
            height,
            width,
            trace_id,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackHomepageCreateSessionComplete(trace_id: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.HOMEPAGE_CREATE_SESSION_COMPLETE;
        const eventPayload = { trace_id };
        await this.trackData(eventType, eventPayload);
    }

    public async trackHomepageOffersComplete(trace_id: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.HOMEPAGE_OFFERS_COMPLETE;
        const eventPayload = { trace_id };
        await this.trackData(eventType, eventPayload);
    }

    public async trackHomepageScroll(): Promise<void> {
        const eventType = TrackingServiceEventTypes.HOMEPAGE_SCROLL;
        await this.trackData(eventType);
    }

    public async trackInitialLoadTime(duration: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.INITIAL_LOAD_TIME;
        const eventPayload = {
            duration,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackMiniWidgetBillboardLoad(billboardId: string): Promise<void> {
        const eventType = MiniWidgetTrackingEventTypes.MINI_WIDGET_BILLBOARD_LOAD;
        const eventPayload = {
            billboard_id: billboardId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackMiniWidgetClickExitPrizeout(): Promise<void> {
        const eventType = MiniWidgetTrackingEventTypes.MINI_WIDGET_CLICK_EXIT_PRIZEOUT;
        await this.trackData(eventType);
    }

    public async trackMiniWidgetSeeMoreClick(type: MiniWidgetButtonTypes): Promise<void> {
        const eventType = MiniWidgetTrackingEventTypes.MINI_WIDGET_SEE_MORE_CLICK;
        const state = store.getState();
        const eventPayload = {
            checkout_value_id: selectActiveCheckoutValueId(state),
            type,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackMoreDetailsRedirectUrlClick(gift_card_request_id: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.MORE_DETAILS_REDIRECT_URL_CLICK;

        const eventPayload = {
            gift_card_request_id,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackNaviation(): Promise<void> {
        const eventType = TrackingServiceEventTypes.NAVIGATION;
        await this.trackData(eventType);
    }

    public async trackNoOffersPop(): Promise<void> {
        const eventType = TrackingServiceEventTypes.NO_OFFERS_POP;
        await this.trackData(eventType);
    }

    public async trackOfferPageClickExitPrizeout(): Promise<void> {
        const eventType = TrackingServiceEventTypes.OFFER_PAGE_CLICK_EXIT_PRIZEOUT;
        await this.trackData(eventType);
    }

    public async trackOffersClickExitPrizeout(): Promise<void> {
        const eventType = TrackingServiceEventTypes.OFFERS_CLICK_EXIT_PRIZEOUT;
        await this.trackData(eventType);
    }

    private async trackOnboarding(
        eventType: OnboardingTrackingTypes,
        onboardingStep: OnboardingStepTypes,
        payloadValues: {
            description?: string;
            slide_index?: number;
            panel_count?: number;
        },
    ) {
        const state = store.getState();
        const payload = {
            consumer_onboarding_flow_id: selectConsumerOnboardingFlowId(state),
            onboarding_step: onboardingStep,
            ...payloadValues,
        };

        await this.trackData(eventType, payload);
    }

    public async trackOnboardingContinue(
        onboardingStep: OnboardingStepTypes,
        description: string,
        slideIndex = 0,
    ): Promise<void> {
        await this.trackOnboarding(TrackingServiceEventTypes.ONBOARDING_CONTINUE, onboardingStep, {
            description: description,
            slide_index: slideIndex,
        });
    }

    public async trackOnboardingExit(
        onboardingStep: OnboardingStepTypes,
        description: string,
        slideIndex = 0,
    ): Promise<void> {
        await this.trackOnboarding(TrackingServiceEventTypes.ONBOARDING_EXIT, onboardingStep, {
            description: description,
            slide_index: slideIndex,
        });
    }

    public async trackOnboardingOpen(onboardingStep: OnboardingStepTypes, panelCount = 0): Promise<void> {
        await this.trackOnboarding(TrackingServiceEventTypes.ONBOARDING_OPEN, onboardingStep, {
            panel_count: panelCount,
        });
    }

    public async trackOpenCheckoutPageEmailModal(giftCard: string, userBalance: string, offer: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.OPEN_CHECKOUT_PAGE_EMAIL_MODAL;
        const eventPayload = {
            offer,
            gift_card: giftCard,
            user_balance: userBalance,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackOptInsComplete(accepted: Record<string, unknown>, locationFromSDK: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.OPT_INS_COMPLETE;
        const eventPayload = {
            accepted,
            location_from_sdk: locationFromSDK,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackOptInsOpen(): Promise<void> {
        const eventType = TrackingServiceEventTypes.OPT_INS_OPEN;
        await this.trackData(eventType);
    }

    public async trackOptInsRequest(
        accepted: Record<string, unknown>,
        locationFromSDK: string,
        submitted: Record<string, unknown>,
    ): Promise<void> {
        const eventType = TrackingServiceEventTypes.OPT_INS_REQUEST;
        const eventPayload = {
            accepted,
            submitted,
            location_from_sdk: locationFromSDK,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackOtpClick(
        eventType: OtpTrackingTypes,
        payloadValues?: {
            checkout_value_id?: string;
            method?: OTPVerificationOptionType;
        },
    ): Promise<void> {
        const state = store.getState();
        const eventPayload = {
            otp_request_id: selectActiveOtpRequestId(state),
            ...payloadValues,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackPillClick(name: string, onClose: boolean, location: string): Promise<void> {
        const eventType = `${name}_${TrackingServiceEventTypes.PILL_CLICK}`;
        const eventPayload = {
            onClose,
            location,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackSearchEvent(eventType: SearchTrackingTypes, eventPayload?: any): Promise<void> {
        await this.trackData(eventType, eventPayload);
    }

    public async trackPostCheckoutClick(buttonType: PostCheckoutButtonTypes): Promise<void> {
        const eventType = TrackingServiceEventTypes.POST_CHECKOUT_CLICK;
        const eventPayload = {
            button_type: buttonType,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackProductTourBubbleOpen(): Promise<void> {
        const eventType = TrackingServiceEventTypes.PRODUCT_TOUR_BUBBLE_OPEN;
        await this.trackData(eventType);
    }

    public async trackProductTourClick(): Promise<void> {
        const eventType = TrackingServiceEventTypes.PRODUCT_TOUR_CLICK;
        await this.trackData(eventType);
    }

    public async trackProductTourClickedOverlay(): Promise<void> {
        const eventType = TrackingServiceEventTypes.OPT_PRODUCT_TOUR_CLICKED_OVERLAYINS_OPEN;
        await this.trackData(eventType);
    }

    public async trackProductTourClose(): Promise<void> {
        const eventType = TrackingServiceEventTypes.PRODUCT_TOUR_CLOSE;
        await this.trackData(eventType);
    }

    public async trackProductTourError(): Promise<void> {
        const eventType = TrackingServiceEventTypes.PRODUCT_TOUR_ERROR;
        await this.trackData(eventType);
    }

    public async trackProductTourOpen(): Promise<void> {
        const eventType = TrackingServiceEventTypes.PRODUCT_TOUR_OPEN;
        await this.trackData(eventType);
    }

    public async trackQuestionMarkClicked(): Promise<void> {
        const eventType = TrackingServiceEventTypes.QUESTION_MARK_CLICKED;
        await this.trackData(eventType);
    }

    public async trackSDKInitNoEmailFailure(user: Record<string, unknown>): Promise<void> {
        const eventType = TrackingServiceEventTypes.SDK_INIT_NO_EMAIL_FAILURE;
        const eventPayload = {
            user,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackSelectedEmptyOffers(location: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.LOCATION_EMPTY_OFFERS_POP;
        const eventPayload = {
            location,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackShopNowExternalLinkClick(gift_card_request_id: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.SHOP_NOW_EXTERNAL_LINK_CLICK;

        const eventPayload = {
            gift_card_request_id,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackSidebarMenuClick(eventType: SidebarMenuTrackingTypes): Promise<void> {
        await this.trackData(eventType);
    }

    public async trackSliderEvent(): Promise<void> {
        const eventType = TrackingServiceEventTypes.SLIDER_EVENT;
        await this.trackData(eventType);
    }

    public async trackStartPrizeoutClick(
        checkoutValueId: string,
        userBalance: number,
        categoryId?: number,
    ): Promise<void> {
        const eventType = TrackingServiceEventTypes.START_PRIZEOUT_CLICK;
        const eventPayload = {
            checkout_value_id: checkoutValueId,
            user_balance: userBalance,
            category_id: categoryId,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackStripeCardSetupFailed(): Promise<void> {
        const eventType = TrackingServiceEventTypes.STRIPE_CARD_SETUP_FAILED;
        await this.trackData(eventType);
    }

    public async trackStripeCardSetupSuccess(): Promise<void> {
        const eventType = TrackingServiceEventTypes.STRIPE_CARD_SETUP_SUCCESS;
        await this.trackData(eventType);
    }

    public async trackWebsiteClickExitPrizeout(): Promise<void> {
        const eventType = TrackingServiceEventTypes.WEBSITE_CLICK_EXIT_PRIZEOUT;
        await this.trackData(eventType);
    }

    public async trackWebsiteHomepageLoadComplete(): Promise<void> {
        const eventType = TrackingServiceEventTypes.WEBSITE_HOMEPAGE_LOAD_COMPLETE;
        await this.trackData(eventType);
    }

    public async trackWidgetEntryEmailPop(): Promise<void> {
        const eventType = TrackingServiceEventTypes.WIDGET_ENTRY_EMAIL_POP;
        await this.trackData(eventType);
    }

    public async trackWidgetEntryEmailSubmit(): Promise<void> {
        const eventType = TrackingServiceEventTypes.WIDGET_ENTRY_EMAIL_SUBMIT;
        await this.trackData(eventType);
    }

    public async trackYourGiftCardsClickExitPrizeout(): Promise<void> {
        const eventType = TrackingServiceEventTypes.YOUR_GIFTCARDS_CLICK_EXIT_PRIZEOUT;
        await this.trackData(eventType);
    }

    public async trackNavBarClick(navBarName: NavBarButtonIconNames, navBarLocation: NavBarLocation): Promise<void> {
        const eventType = TrackingServiceEventTypes.NAV_BAR_CLICK;
        const eventPayload = {
            nav_bar_name: navBarName,
            nav_bar_location: navBarLocation,
        };
        await this.trackData(eventType, eventPayload);
    }

    public async trackUseGiftCardExternalLinkClick(requestId: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.USE_GIFT_CARD_EXTERNAL_LINK_CLICK;
        const eventPayload = {
            request_id: requestId,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackClaimShopNowExternalLinkClick(requestId: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.SHOP_NOW_EXTERNAL_LINK_CLICK;
        const eventPayload = {
            request_id: requestId,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackClaimMoreDetailsRedirectUrlClick(requestId: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.MORE_DETAILS_REDIRECT_URL_CLICK;
        const eventPayload = {
            request_id: requestId,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackWalletClick(eventType: WalletTrackingTypes): Promise<void> {
        const state = store.getState();
        const eventPayload = {
            gift_card_request_id: selectActiveGiftCardRequestId(state),
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackMiniWidgetTileLoaded(displayType: MiniWidgetDisplayTypes): Promise<void> {
        const eventType = TrackingServiceEventTypes.MINI_WIDGET_TILE_LOADED;
        const eventPayload = {
            display_type: displayType,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackMiniWidgetErrorRendered(): Promise<void> {
        const eventType = TrackingServiceEventTypes.MINI_WIDGET_ERROR_SCREEN_DISPLAYED;

        await this.trackData(eventType);
    }

    public async trackMiniWidgetOfferClicked(offerName: string): Promise<void> {
        const eventType = TrackingServiceEventTypes.MINI_WIDGET_OFFER_CLICKED;
        const eventPayload = {
            offer_name: offerName,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackRulesetStart(eventId: string, giftCardId: string, domain: string, rulesetId: string) {
        const eventType = TrackingServiceEventTypes.RULESET_START;
        const eventPayload = {
            event_id: eventId,
            gift_card_id: giftCardId,
            domain: domain,
            ruleset_id: rulesetId,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackRulesetSuccess(eventId: string, giftCardId: string, domain: string, rulesetId: string) {
        const eventType = TrackingServiceEventTypes.RULESET_SUCCESS;
        const eventPayload = {
            event_id: eventId,
            gift_card_id: giftCardId,
            domain: domain,
            ruleset_id: rulesetId,
        };

        await this.trackData(eventType, eventPayload);
    }

    public async trackRulesetFail(
        eventId: string,
        giftCardId: string,
        domain: string,
        rulesetId: string,
        message: string,
    ) {
        const eventType = TrackingServiceEventTypes.RULESET_FAIL;
        const eventPayload = {
            event_id: eventId,
            gift_card_id: giftCardId,
            domain,
            ruleset_id: rulesetId,
            message,
        };

        await this.trackData(eventType, eventPayload);
    }
}
