import React from 'react';
import Classnames from 'classnames';

import './solid-callout-with-highlight.scss';

interface SolidCalloutWithHighlightProps {
    preText: string;
    postText: string;
    highlightedText: string;
    iconClasses?: string;
    isInline?: boolean;
}

export const SolidCalloutWithHighlight: React.FC<SolidCalloutWithHighlightProps> = ({
    preText,
    postText,
    highlightedText,
    iconClasses,
    isInline,
}): React.ReactElement => {
    const classes = Classnames('solid-callout-with-highlight', { 'solid-callout-with-highlight--is-inline': isInline });

    return (
        <article data-testid="solid-callout-with-highlight" className={classes}>
            {iconClasses && (
                <div className="solid-callout-with-highlight__icon-wrapper">
                    <i className={iconClasses}></i>
                </div>
            )}

            <p>
                {preText} <span className="solid-callout-with-highlight__highlight">{highlightedText}</span> {postText}
            </p>
        </article>
    );
};
