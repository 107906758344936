import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestPasswordResetFormRequest } from '../components/common';
import { sharedAPI } from '../utils/services/api';
import { RootState } from '../store';
import { authenticateUser, authenticateWidgetUser, VerificationRecord } from './verification-slice';
import { getPartnerConfig } from './partner-config-slice';

// Define a type for the slice state
export interface UserState {
    passwordReset?: PasswordResetFlow;
    user?: User;
    connectedPartners?: ConnectedPartner[];
    optIns?: OptIn[];
}

export interface ConnectedPartner {
    email: string;
    emailIsLocked: boolean;
    name: string;
    logo: string;
    id: string;
}

export interface OptIn {
    super_user_id: string;
    opt_in: number;
    opt_in_type: string;
    update_date?: string;
}

type PasswordResetFlow = {
    email: string;
    token?: string;
};

type Gender = 'M' | 'F';

export interface User {
    firstName: string;
    lastName: string;
    email: string;
    gender: Gender;
    dateOfBirth: string;
    superUserId: string;
    accessToken: string;
    signUpEmail: string;
    linkFromEmail: string;
    needsToLink: boolean;
}

export interface LogInResponse extends User {
    activeAuthVerification?: VerificationRecord;
    needsToLink: boolean;
    verifiedAuth: boolean;
    error?: boolean;
    message?: string;
    email: string;
    linkFromEmail: string;
    accountsPartnerId?: string;
    accountsSessionId?: string;
}

// Define the initial state using that type
export const userInitialState: UserState = {
    optIns: [],
    connectedPartners: [],
    passwordReset: null,
    user: null,
};

type UpdatePasswordWithVerificationRequest = {
    id: string;
    password: string;
    secretToken: string;
};

type PasswordResetTokenResponse = {
    email: string;
};

type VerifyPasswordResetTokenRequest = {
    email: string;
    token: string;
};

type VerifyPasswordResetTokenResponse = {
    token: string;
};

export interface acceptOptinsV2Params {
    optIn: boolean;
    prizeoutUserOptInId: string;
}

export const logOut = createAsyncThunk('logOut', async (_, { rejectWithValue, signal }) => {
    try {
        const results = await sharedAPI.request({
            data: {},
            endpoint: '/logOut',
            method: 'POST',
            signal: signal,
        });
        return results.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const listOptIns = createAsyncThunk('account/listOptIns', async (_, { rejectWithValue, signal }) => {
    try {
        const results = await sharedAPI.request({
            data: {},
            endpoint: '/account/listOptIns',
            method: 'GET',
            signal: signal,
        });
        return results.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const acceptOptins = createAsyncThunk(
    'account/acceptOptins',
    async (optIns: string[], { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    optIns,
                },
                endpoint: '/account/acceptOptins',
                method: 'POST',
                signal: signal,
            });
            return results.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const acceptOptinsV2 = createAsyncThunk(
    'account/acceptOptins',
    async ({ optIn, prizeoutUserOptInId }: acceptOptinsV2Params, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    optIn,
                    prizeoutUserOptInId,
                },
                endpoint: '/account/optIns/accept',
                method: 'POST',
                signal: signal,
            });
            return results.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const requestPasswordResetEmail = createAsyncThunk(
    'user/requestPasswordResetToken',
    async ({ email, recaptchaToken }: RequestPasswordResetFormRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    email,
                    recaptchaToken,
                },
                endpoint: '/requestPasswordResetEmail',
                method: 'POST',
                signal: signal,
            });
            return {
                email,
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const updatePasswordUsingVerification = createAsyncThunk(
    'user/updatePasswordUsingVerification',
    async ({ id, secretToken, password }: UpdatePasswordWithVerificationRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    id,
                    secretToken,
                    password,
                },
                endpoint: `/verification/processVerification`,
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const verifyPasswordResetToken = createAsyncThunk(
    'user/verifyPasswordResetToken',
    async ({ email, token }: VerifyPasswordResetTokenRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    email,
                    token,
                },
                endpoint: '/verifyPasswordResetToken',
                method: 'GET',
                signal: signal,
            });
            return {
                token,
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadConnectedPartners = createAsyncThunk(
    'account/loadConnectedPartners',
    async (_, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: '/account/linkedPartners',
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const userSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(authenticateUser.fulfilled, (state, action: PayloadAction<LogInResponse>) => {
            if (!action.payload.error) {
                state.user = {
                    accessToken: action.payload.accessToken,
                    dateOfBirth: action.payload.dateOfBirth,
                    email: action.payload.email,
                    firstName: action.payload.firstName,
                    gender: action.payload.gender,
                    lastName: action.payload.lastName,
                    superUserId: action.payload.superUserId,
                    signUpEmail: null,
                    linkFromEmail: action.payload.linkFromEmail,
                    needsToLink: action.payload.needsToLink,
                };
            }
        });

        builder.addCase(
            requestPasswordResetEmail.fulfilled,
            (state, action: PayloadAction<PasswordResetTokenResponse>) => {
                state.passwordReset = {
                    ...state.passwordReset,
                    email: action.payload.email,
                };
            },
        );

        builder.addCase(
            verifyPasswordResetToken.fulfilled,
            (state, action: PayloadAction<VerifyPasswordResetTokenResponse>) => {
                state.passwordReset = {
                    ...state.passwordReset,
                    token: action.payload.token,
                };
            },
        );

        builder.addCase(
            loadConnectedPartners.fulfilled,
            (state, action: PayloadAction<{ data: ConnectedPartner[] }>) => {
                state.connectedPartners = [...action.payload?.data];
            },
        );

        builder.addCase(listOptIns.fulfilled, (state, action: PayloadAction<OptIn[]>) => {
            state.optIns = [...action.payload];
        });

        builder.addCase(acceptOptins.fulfilled, (state, action: PayloadAction<OptIn[]>) => {
            state.optIns = state.optIns.concat([...action.payload]);
        });

        builder.addCase(authenticateWidgetUser.fulfilled, (state, action: PayloadAction<User>) => {
            state.user = {
                dateOfBirth: action.payload.dateOfBirth,
                email: action.payload.email,
                firstName: action.payload.firstName,
                gender: action.payload.gender,
                lastName: action.payload.lastName,
                superUserId: action.payload.superUserId,
                accessToken: action.payload.accessToken,
                signUpEmail: null,
                linkFromEmail: null,
                needsToLink: false,
            };
        });

        builder.addCase(getPartnerConfig.fulfilled, (state, action: PayloadAction<User>) => {
            state.user = {
                dateOfBirth: action.payload.dateOfBirth,
                email: action.payload.email,
                firstName: action.payload.firstName,
                gender: action.payload.gender,
                lastName: action.payload.lastName,
                superUserId: action.payload.superUserId,
                accessToken: action.payload.accessToken,
                signUpEmail: null,
                linkFromEmail: null,
                needsToLink: false,
            };
        });
    },
    initialState: userInitialState,
    name: 'user',
    reducers: {
        setSignUpEmail(state, action: PayloadAction<string>) {
            if (state.user) {
                state.user.signUpEmail = action.payload;
            }
        },
        resetUserSlice() {
            return userInitialState;
        },
    },
});

export const { resetUserSlice, setSignUpEmail } = userSlice.actions;

const selectUserState = ({ user }: RootState): UserState => user;

export const selectSuperUserId = createSelector(selectUserState, ({ user }) => user?.superUserId);

export const selectEmail = createSelector(selectUserState, ({ user }) => user?.email);

export const selectPasswordResetEmail = createSelector(selectUserState, ({ passwordReset }) => passwordReset?.email);

export const selectPasswordResetToken = createSelector(selectUserState, ({ passwordReset }) => passwordReset?.token);

export const selectUserAccessToken = createSelector(selectUserState, ({ user }) => user?.accessToken);

export const selectUser = createSelector(selectUserState, ({ user }) => user);

export const selectSignUpEmail = createSelector(selectUserState, ({ user }) => user?.signUpEmail);

export const selectLinkFromEmail = createSelector(selectUserState, ({ user }) => user?.linkFromEmail);

export const selectConnectedPartners = createSelector(selectUserState, ({ connectedPartners }) => connectedPartners);

export const selectOptins = createSelector(selectUserState, ({ optIns }) => optIns);

// TODO refactor selector so that it doesn't take an argument
export const selectHasOptin = (optInName: string) => {
    return ({ user }: RootState): boolean => {
        return !!(
            user.optIns &&
            user.optIns.find((optIn) => {
                return optIn.opt_in_type === optInName;
            })
        );
    };
};

export const selectHasConnectedPartners = createSelector(
    selectUserState,
    ({ connectedPartners }) => !!connectedPartners.length,
);

export const selectHasLockedDeliveryPreferences = createSelector(selectUserState, ({ connectedPartners }) => {
    if (connectedPartners) {
        let hasLockedDeliveryPreferences = false;
        connectedPartners.forEach((partner) => {
            if (partner.emailIsLocked) {
                hasLockedDeliveryPreferences = true;
            }
        });
        return hasLockedDeliveryPreferences;
    } else {
        return false;
    }
});

export const selectUserSentryData = createSelector(selectUser, (user) => {
    if (user) {
        const { firstName, lastName, gender, email, superUserId } = user;
        return {
            firstName,
            lastName,
            gender,
            email,
            superUserId,
        };
    }
});

export default userSlice.reducer;
