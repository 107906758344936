import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { basePortalQuery } from '../common';
import {
    PartnerRewardStatistics,
    PartnerRewardStatisticsArgs,
    PortalEventGroups,
    RewardsUser,
    RewardsUsersQuery,
    UserCashbackSummaryResponse,
    UserRewards,
    UserRewardsQuery,
} from './partner-portal-types';
import { PartnerReward } from '../rewards';
import { PaginatedData } from '../../utils';

export const portalApiSlice = createApi({
    reducerPath: 'partnerPortalApi',
    baseQuery: basePortalQuery,
    tagTypes: [PortalEventGroups.PortalRewardsSummaryData, PortalEventGroups.PortalRewardsStatistics],
    endpoints: (builder) => ({
        getPartnerRewardsList: builder.query<PartnerReward[], void>({
            query: () => '/partner/rewards/list',
            providesTags: [PortalEventGroups.PortalRewardsSummaryData],
        }),
        getPartnerRewardStatistics: builder.query<PartnerRewardStatistics, PartnerRewardStatisticsArgs>({
            query: (args: PartnerRewardStatisticsArgs) => {
                const query = args.query;

                return {
                    url: `/partner/rewards/rewardStatistic/${args.partnerRewardId}`,
                    params: query,
                };
            },
            providesTags: [PortalEventGroups.PortalRewardsStatistics],
        }),
        getUserRewardsList: builder.query<PaginatedData<UserRewards[]>, UserRewardsQuery>({
            query: (args: UserRewardsQuery) => {
                return {
                    url: '/partner/rewards/userSummary',
                    params: args,
                };
            },
        }),
        getRewardsUsersList: builder.query<PaginatedData<RewardsUser[]>, RewardsUsersQuery>({
            query: (args: RewardsUsersQuery) => {
                return {
                    url: '/partner/prizeoutUser',
                    params: args,
                };
            },
        }),
        getUserCashbackSummary: builder.query<UserCashbackSummaryResponse, string>({
            query: (userId: string) => ({
                url: `partner/prizeoutUser/${userId}/cashbackSummary`,
            }),
        }),
    }),
});

export const {
    useGetPartnerRewardsListQuery,
    useGetPartnerRewardStatisticsQuery,
    useGetUserRewardsListQuery,
    useGetRewardsUsersListQuery,
    useGetUserCashbackSummaryQuery,
} = portalApiSlice;
